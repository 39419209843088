import './css/techs.css'
import {fetchData}  from './ConnectFirebase';
import { useEffect, useState } from 'react';
import Loading from './Loading';


const Techs = ({technos}) => {
    const [techs, setTechs] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (technos) {
            setTechs(technos);
            setLoading(false);
            console.log('technos ', technos);
            console.log('technos ', techs);
        }
        else {
            fetchData('techs')
                .then((techs) => {
                    setTechs(techs);
                    setLoading(false);
            });
        }
    }, []);

    return (
            <section className='techs' >
            <div className="container">
                {
                    isLoading && <Loading />
                }
                {

                    techs && techs.map((tech) => {
                        return <div className="box" key={tech.title}>
                            <div 
                                className="tech"
                                style={
                                    {backgroundImage: `url(${tech.url})`,}
                                }
                            ></div>
                            <div className="content">
                                <h5>{tech.title}</h5>
                            </div>
                        </div>
                    })
                }
            </div>
        </section>
    );
}

export default Techs;