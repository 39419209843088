import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, child } from 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyBf3mUT4JvTMB3KBb8MwtX1BeHmjJE3L1Y",
    authDomain: "portfolio-52973.firebaseapp.com",
    databaseURL: "https://portfolio-52973-default-rtdb.firebaseio.com",
    projectId: "portfolio-52973",
    storageBucket: "portfolio-52973.appspot.com",
    messagingSenderId: "807844177947",
    appId: "1:807844177947:web:f96f14b983ded83cea70be"
};


const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

async function getBlogs(id, type) {
    const dbRef = ref(db);
    try {
        const snapshot = await get(child(dbRef, 'blogs'));
        if (snapshot.exists()) {
            const blogList = snapshot.val();
            if (id >= 0) {
                if (blogList[id]) {
                    return blogList[id];
                }
                return {"error" : "Not found"}
            }
            // Convert object to array if necessary
            if (type) {
                return blogList.filter((blog) => blog.tag === type);
            }
            // return Object.keys(blogList).map(key => blogList[key]);
            return blogList;
        } else {
            console.log("No data available");
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}


async function fetchData(path) {
    const dbRef = ref(db);
    try {
        const snapshot = await get(child(dbRef, path));
        if (snapshot.exists()) {
            const data = snapshot.val();
            return Object.keys(data).map(key => data[key]);
        } else {
            console.log("No data available");
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }

}
// const blogs = await getBlogs(db);


export {getBlogs, fetchData};