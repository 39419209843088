import './css/about.css'
import { Link } from "react-router-dom";
import Typewriter from './TypeEffect';
import Projects from './Projects';
import SpecialHeading from './SpecialHeading';
import Techs from './Techs';
import { useRef, useEffect } from 'react';
import gsap from 'gsap';

const About = () => {
    const txtConRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        const txtCon = txtConRef.current;
        const img = imgRef.current;
        // Set initial position
        gsap.set(txtCon, { x: '20%', opacity: 0 });
        gsap.set(img, { y: '20%', opacity: 0 });

        // Animate the txtCon to its place smoothly
        gsap.to(txtCon, {
            opacity: 1,
            x: 0,
            duration: 5, // Animation duration in seconds
            ease: 'power3.out', // Easing function for smooth movement
        });
        gsap.to(img, {
            opacity: 1,
            y: 0,
            duration: 5, // Animation duration in seconds
            ease: 'power3.out', // Easing function for smooth movement
        });
    }, []);

    return (
        <>
            <section className="about">
                <div className="container">
                    <div ref={txtConRef} className="text-content">
                        <div className='title'>
                            {/* <h2 style={{fontSize: '50px'}}>Hi I'm</h2> */}
                            <h2>Hi I'm</h2>
                            <Typewriter text={'Ayoub Mentag'} delay={150} tag={'h2'} color='#26dcfc'/>
                        </div>
                        {/* <h2 style={{color: '#26dcfc'}}>Ayoub Mentag</h2> */}
                        <div className='description'>
                            <p>Young full-stack developer creating smooth web experiences with front and back-end tech</p>
                            <div className="links">
                                <Link to='/contact'>Contact</Link>
                                <Link to='/projects'>Projects <i className="fa-solid fa-arrow-up"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div ref={imgRef} className="image"></div>
                </div>
            </section>
            <SpecialHeading title={['Last Projects', 'Explore Projects']}/>
            <Projects size={3}/>
            <SpecialHeading title={['Technologies I use', 'Explore Technologies']}/>
            <Techs />
        </>
    );
}

export default About;