import './css/contact.css'
import React, { useState } from 'react';
import SpecialHeading from './SpecialHeading';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        msg: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
        ...prevState,
        [name]: value
        }));
    };

    const handleSubmit = async (e) => {
    e.preventDefault();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    try {
        setFormData({name: '',email: '',msg: ''});
        document.getElementsByClassName('sent')[0].style.opacity = 1;
        // await fetch('http://localhost:5000', requestOptions);
        await fetch('https://us-central1-portfolio-52973.cloudfunctions.net/api/hello', requestOptions);
        document.getElementsByClassName('sent')[0].innerHTML = "Email Sent successfully";
        setTimeout(function() {
            document.getElementsByClassName('sent')[0].style.opacity = 0;
        }, 1000);
    } catch (error) {
        console.error('Error:', error);
    }
};

    return (
        <section className='contact'>
            <div className="container">
                <SpecialHeading title={['Contact', 'Get In Touch']} />
                <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Full Name</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder='Enter Your name...' required />
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Enter Your email...' required />
                </div>
                <div>
                    <label htmlFor="msg">Message</label>
                    <textarea name="msg" value={formData.msg} onChange={handleChange} placeholder='Enter your message ...' required />
                </div>
                <input type="submit" value="Send Message" />
                </form>
                <div className='sent'>
                    <span>Sending email</span>
                </div>
            </div>
        </section>
    );
}

export default Contact;
