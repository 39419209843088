import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from 'react';
import './css/project.css'
import { getBlogs } from "./ConnectFirebase";
import Techs from "./Techs";
import NotFound from "./err/404";

const Project = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        getBlogs(id).then((blog) => setBlog(blog));
    }, []);

    if (blog) {
        if (blog.error)
            return <NotFound />
        return (
            <section className="project">
                {blog.error}
                <div className="container">
                    <div className="box">
                        <div className="image" style={{backgroundImage: `url(${blog?.imgUrl})`}}></div>
                        <div className="text">
                            <div>
                                <span>
                                    <i className="fa-brands fa-github"></i>
                                    <a href={blog?.link}>Link To project</a>
                                </span>
                                <span className="date">Create at {blog?.date}</span>
                            </div>
                            <h2>Description</h2>
                            <p>{blog?.description}</p>
                            <h2>Technologies I used </h2>
                            {blog && <Techs technos={Object.keys(blog.techs).map(key => blog.techs[key])}/>}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Project;