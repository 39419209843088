import './css/footer.css'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className="box">
                    <Link to='linkedin.com'><i class="fa-brands fa-linkedin"></i>Ayoub Mentag</Link>
                </div>
                <div className="box">
                    <Link to='/contact'><i class="fa-solid fa-envelope"></i>amentag@gmail.com</Link>
                </div>
                <div className="box">
                    <Link to=''><i class="fa-brands fa-github"></i>Ayoub Mentag</Link>
                </div>
            </div>
            <h5>Coded with <i class="fa-solid fa-heart"></i> By amentag</h5>
        </footer>
    );
}

export default Footer;