import './css/loading.css'

const Loading = () => {
    return (
        <div className="loading">
            <div></div>
            <div></div>
        </div> 
    );
}

export default Loading;