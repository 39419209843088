import './css/header.css'
import { useState } from 'react';

const Header = () => {
    // State to manage the visibility of the list
    const [isListVisible, setIsListVisible] = useState(false);

    const toggleListVisibility = () => {
        setIsListVisible(!isListVisible);
    };

    return (
        <header>
            <div className="container">
                <h1 className="logo"><a href="/">Ayoub</a></h1>
                <div>
                    <i class="fa-solid fa-bars fa-3x" onClick={toggleListVisibility}></i>
                    {
                        <ul style={{opacity: isListVisible ? 1 : 0}}>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/projects">Projects</a>
                            </li>
                            <li>
                                <a href="/contact">Contact</a>
                            </li>
                            <li>
                                <a href="https://drive.google.com/file/d/1yFTRM_FT71LNWvvnLrfBAZa9CDT_guqk/view?usp=sharing" target='_blank' rel="noreferrer"><i className="fa-solid fa-download"></i>CV</a>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;