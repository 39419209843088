import './css/textDescription.css'
import { Link } from "react-router-dom";

const TextDescription = ({title, description, btn1Url, btn2, tag}) => {
    return (
        <div className="text-description">
            {/* {
                title.map((c) => <h2>{c}</h2>)
            } */}
            <span>{tag}</span>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="links">
                <Link to={btn1Url}><i class="fa-solid fa-arrow-up"></i></Link>
                {
                    btn2 && <a href="/projects">Projects<i className="fa-solid fa-arrow-up"></i></a>
                }
            </div>
        </div>
    );
}

export default TextDescription;