import React, { useState, useEffect, createElement } from 'react';

const Typewriter = ({ text, delay, tag, color }) => {
  const [currentText, setCurrentText] = useState(text[0]);
  const [currentIndex, setCurrentIndex] = useState(1);

  // Typing logic goes here
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return createElement(tag, {style: {color: color}}, currentText);
};

export default Typewriter;