import './css/projects.css'
import TextDescription from './TextDescription';
import { getBlogs } from './ConnectFirebase'
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Loading from './Loading';

const Projects = ({ size, toggle }) => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [display, setDisplay] = useState(true);
    const [type, setType] = useState(undefined);

    useEffect(() => {
        getBlogs(-1)
            .then((blogs) => {
                if (size)
                    setBlogs(blogs.slice(0, size));
                else
                    setBlogs(blogs);
                setLoading(false);
            });
    }, []);

    function displayList() {
        const ul = document.getElementsByClassName('toggleList')[0];
        const i = document.getElementsByClassName('fa-play')[0];
        if (display) {
            i.style.transform = 'rotate(-90deg)';
            ul.style.opacity = 1;
        } else {
            i.style.transform = 'rotate(90deg)';
            ul.style.opacity = 0;
        }
        setDisplay(!display);
    }

    function changeBlogs(type) {
        setType(type);
        setDisplay(!display);
        displayList();
        getBlogs(-1, type)
            .then((blogs) => {
                console.log(blogs);
                if (size)
                    setBlogs(blogs.slice(0, size));
                else
                    setBlogs(blogs);
                setLoading(false);
        });
    }

    return (
        <section className="projects">
            <div className="container">
                { isLoading && <Loading />}
                {
                    !isLoading && toggle &&
                    <div className="toggle">
                        <button onClick={displayList}>{!type ? 'All' : type} <i class="fa-solid fa-play"></i></button>
                        <ul className="toggleList">
                            <li onClick={() => changeBlogs()}>All</li>
                            <li onClick={() => changeBlogs('Web')}>Web</li>
                            <li onClick={() => changeBlogs('Low Level')}>Low Level</li>
                            <li onClick={() => changeBlogs('Docker')}>Docker</li>
                        </ul>
                    </div>
                }
                {
                    blogs && 
                    <div>
                        {
                            blogs.map((blog, index) => {
                                return (
                                    <div className="box" key={blog.id}>
                                        <TextDescription
                                            title={[blog.title]}
                                            description={blog.description}
                                            btn1Url={`/projects/${index}`}
                                            tag={blog.tag}
                                        />
                                        <div 
                                            className="image"
                                            style={{
                                                backgroundImage: `url(${blog.imgUrl})`,
                                                order: (index % 2) ? -1 : 1,
                                            }}>
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                }
                {
                    size && 
                    <div className='visit'>
                        {/* <Link to='/projects'>View More projects <i class="fa-solid fa-caret-right"></i></Link> */}
                        <Link to='/projects'>View More projects</Link>
                    </div>
                }
            </div>
        </section>
    );
}

export default Projects;