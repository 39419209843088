import './css/specialHeading.css'

const SpecialHeading = ({title}) => {
    return (
        <div className="specialHeading">
            {title.map((value) => <h2>{value}</h2>)}
        </div>
    );
}

export default SpecialHeading;